import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const HomeCard = ({ CardIcon, title, text, link }) => (
  <Grid
    item
    xs={12}
    lg={3.8}
    sx={{ zIndex: 4, textDecoration: 'none' }}
    component={Link}
    to={link}
  >
    <Paper sx={{ borderRadius: 4, height: '100%' }}>
      <Stack p={4} spacing={2}>
        <CardIcon
          fontSize="large"
          sx={{
            borderRadius: 10000,
            p: 1,
            backgroundColor: 'text.primary',
            color: 'gray.A100',
            alignSelf: 'center',
          }}
        />
        <Typography variant="h5" align="center" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="body1" align="center">
          {text}
        </Typography>
      </Stack>
    </Paper>
  </Grid>
);

HomeCard.propTypes = {
  CardIcon: PropTypes.shape({
    $$typeof: PropTypes.symbol.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default HomeCard;
